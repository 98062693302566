<template>
  <div v-if="namedQueryList.length">
    <!-- begin named query selection -->
    <div class="form-group form-group-sm">
      <label for="namedQueryEnabled">{{ $t("source") }}</label>
      <select
        name="namedQueryEnabled"
        id="namedQueryEnabled"
        class="form-control"
        v-model="namedQueryEnabled"
      >
        <option :value="false">{{ $t("data_list") }}</option>
        <option :value="true">{{ $tc("titles.named_query", 1) }}</option>
      </select>
    </div>
    <template v-if="namedQueryEnabled">
      <div class="form-group form-group-sm" style="margin-bottom: 5px">
        <label for="namedQuerySelector">{{ $t("name") }}</label>
        <div class="input-group">
          <select
            v-model="namedQuery"
            class="form-control"
            id="namedQuerySelector"
          >
            <option value="">{{ $t("select") }}</option>
            <option
              v-for="item in namedQueryList"
              :value="item.name"
              :key="item.name"
            >
              {{ item.name }}
            </option>
          </select>
          <div
            class="input-group-addon btn"
            :title="$t('apply')"
            @click.stop.prevent="apply"
            :disabled="!selected"
          >
            <i class="fa fa-check"></i>
          </div>
        </div>
      </div>
    </template>
    <!-- end named query selection -->
  </div>
</template>

<script>
export default {
  name: "DataSetSourceSelector",
  data() {
    return {
      iNamedQueryEnabled: false
    };
  },
  computed: {
    draft() {
      return this.$store.getters["dashboard/draft"] || null;
    },
    template() {
      return this?.draft?.template || null;
    },
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"];
    },
    namedQueryList() {
      return this?.template?.namedQueries || [];
    },
    namedQuery: {
      set(value) {
        this.$set(this.panel.options, "namedQuery", value);
        this.apply();
      },
      get() {
        return this?.panel?.options?.namedQuery || "";
      }
    },
    namedQueryEnabled: {
      set(value) {
        this.iNamedQueryEnabled = value;
        if (this.iNamedQueryEnabled) {
          this.apply();
        } else {
          this.namedQuery = "";
        }
      },
      get() {
        return this.iNamedQueryEnabled;
      }
    },
    selected() {
      return (
        (this.namedQuery &&
          this.namedQueryList.find(({name}) => this.namedQuery == name)) ||
        null
      );
    }
  },
  methods: {
    save() {
      this.$store.dispatch("dashboard/saveDraftPanel", {
        panel: this.panel,
        screenId: this.draft.screenId
      });
    },
    apply() {
      if (this.selected && this.namedQuery) {
        this.$root.$emit("namedQuery:applied", this.namedQuery);
      }
      this.$emit("apply", this.selected);
    }
  },
  created() {
    if (this.namedQuery) this.iNamedQueryEnabled = true;
  }
};
</script>

<style scoped>
</style>